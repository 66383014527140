<template>
    <div id="Login">
       <div class="content_login">
         <div class="logos_lab">
           <div  @click="setlab('laason'),saveLab('laason')" class="logo_la">
             <img v-if="lab =='laason'" src="../assets/laason_act.png" alt="Logo Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.">
             <img v-else  src="../assets/laason_des.jpg" alt="Logo Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.">
           </div>

           <div  @click="setlab('barragan'),saveLab('barragan')" class="logo_lb">
             <img v-if="lab =='barragan'" src="../assets/barraga_act.png" alt="Logo Laboratorio Clinico Barragán">
            <img v-else  src="../assets/barragan_des.jpg" alt="Logo Laboratorio Clinico Barragán">
           </div>
           
         </div>
        
         <div class="form_login">
            <h1>LOGIN</h1>
            <form  @submit.prevent="
                login(
                  email,
                  password
                )
              ">
              <input v-model="email" type="email" name="email" id="email"  :placeholder="'Correo electrónico'" required>

              
              <input v-model="password" type="password" name="pass" id="pass" minlength="8" :placeholder="'Contraseña'" required>

              <!--<input type="checkbox" v-on:click="seePass">-->
              <img class="seeEye" src="../assets/OpenEye.png" v-on:click="seePass(), showEye=true" v-if="!showEye">
              <img class="seeEye" src="../assets/CloseEye.png" v-on:click="seePass(), showEye=false" v-else>

              <button  variant="outline-secondary" type="submit">INICIAR SESIÓN</button>


              <div  class="message_error" v-if="status=='error'">
                  <p>
                    {{this.message}}
                  </p>
              </div>
            </form>
         </div>
       </div>
    </div>
</template>
<script>

import userService from '../services/user.service'
import {mapActions} from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      status:'',
      message:'',
      email:'',
      password:'',
      showEye: false,

    };
  },
  created(){
      let labo = localStorage.getItem('laboratorio')
      if(labo){
        this.setlab(labo)
      }
  },
  computed:{
    lab() {
      return this.$store.getters['main/getlab'];
    },
    identity() {
      return this.$store.getters['admin/getIdentity'];
    }
  },

  methods:{
    ...mapActions('main', ['setlab']),
      wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },

    seePass: function() {

      let contra = document.getElementById('pass')

      if(contra.type=='password'){
        contra.type = "text"
      }else {
        contra.type = "password"
      }
      
    },

     delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,1500);
    },
       saveLab(value){
        localStorage.setItem('laboratorio', value)
    },
    delMsgs: function(){
       this.status=""
       this.message=""
    },
     async login(email, password) {
       this.status =''
       this.message =''
        
        let result =''

        if(this.lab !='laason' && this.lab !='barragan'){
          this.status='error'
          this.message = 'selecciona un laboratorio'
          this.delStatus()
          return
        }


        if(this.lab =='laason'){
            result = await this.$store.dispatch("admin/login",  {email, password});
        }else{
            result = await this.$store.dispatch("admin/login_barragan",  {email, password});
        }
        
       
         if(result.status=='success'){

          let response = await this.$store.dispatch("admin/getData");

        
              
          if(response.status =='success'){
              if(response.result.role =='cliente'){
                 let isCliente =  userService.isClient(response.result)
        

                  if(isCliente.cli == true){ // para el administrador
                          this.$router.push("/cliente/administrador").catch(err => {});
                  }else{ //para el home
                         // this.wait()   this.$router.push("/login").catch(err => {});
                  }


              }else if(response.result.role =='sucursal'){
                   let isSuc =  userService.isSuc(response.result)

                  
                  if(isSuc.suc == true){ // para el administrador
                          this.$router.push("/cliente/sucursal/administrador").catch(err => {});
                  }else{ //para el home
                        this.wait() //   this.$router.push("/login").catch(err => {});
                  }
                  

              }else{
                let isAdmin =  userService.isAdmin(response.result)
                if(response.result.role === 'spadmin'){
                  if(response.result.permisos ==='clinicos'){
                    this.setlab('barragan')
                    this.saveLab('barragan')
                  }else if(response.result.permisos ==='alimentos'){
                    this.setlab('laason')
                    this.saveLab('laason')
                  }
                }

                if(isAdmin.admin == true){ // para el administrador
                        this.$router.push("/administrador").catch(err => {});
                }else{ //para el home
                      this.wait()  // this.$router.push("/login").catch(err => {});
                }
              }

          }else{
             this.status='error'
              this.message = response.message
              this.delStatus()
              this.wait()
          }
        
          /*
          
          let isAdmin =  userService.isAdmin(response.result)
        

          if(isAdmin.admin == true){ // para el administrador
                  this.$router.push("/administrador").catch(err => {});
          }else{ //para el home
                  this.$router.push("/proximamente").catch(err => {});
          }
          */
        

         

        }else{
          this.status='error'
          this.message = result.message
          this.delStatus()
        }
        
     }
  }
}
</script>
<style scoped>

  .message_error p{
     margin-bottom: 0vw !important;
     background:var(--color-7);
     border: 0vw;
     width: 20.46875VW;
     padding: 1vw;
     color: var(--color-5);
     font-weight: 400;
     text-align: center;
     font-size: 0.8333333333333334VW;
     font-style: normal;
     font-family: var(--Sans);
     margin-top:1.8229166666666667VW ;
     transition: all 300ms;

  }




   #Login{
     background-image: url("../assets/Login.png");
     background-repeat: no-repeat;
     background-size: 101%;
     height: 56.25VW;
     font-family: var(--Sans);
   }

   .content_login{
     width: 20.46875VW;
      margin-left: 59.47916666666667VW;
      padding-top: 11.40625VW;
   }

   .logos_lab{
     display: flex;
     margin-bottom: 3.5416666666666665VW;
   }

    .form_login h1{
      color: var(--color-3);
      font-weight: 700;
      margin-bottom: 1.8229166666666667VW;
      font-size: 1.8229166666666667VW;
      line-height: 2.2916666666666665VW;
    }

   .form_login form{
     display: grid;
   }

   .logo_la img{
     width: 9.145833333333334VW;
     height: 8.645833333333334VW;
      margin-right: 1.2770833333333333VW;
   }

   .logo_lb img{
     width: 9.145833333333334VW;
     height: 8.645833333333334VW;
     margin-top: 1.02vw;
    
   }

   .form_login  input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 20.46875VW;
     height: 2.7083333333333335VW; 
     padding-left: 1.8vw;
     font-size: 0.8770833333333334VW;
     color: var(--color-4) !important;
   }

   

   .form_login input:first-child{
     margin-bottom: 1.0416666666666665VW;
   }

   .form_login ::placeholder{
     color: var(--color-6) !important;
     font-size: 0.8770833333333334VW;
   }

   .form_login button{
     background:var(--color-1);
     border: 0vw;
     height: 3.802083333333333VW;
     color: var(--color-5);
     font-weight: 700;
     text-align: center;
     font-size: 0.8333333333333334VW;
     font-style: normal;
     font-family: var(--Sans);
     line-height: 1.0473958333333333VW;
     margin-top:1.8229166666666667VW ;
   }

.active-lab{
  padding-bottom: 1vw;
  border-bottom: 1vw solid var(--color-1);
}

img.seeEye {
  width: 2vw;
  height: auto;
  position: absolute;
  align-self: center;
  justify-self: right;
  margin-left: -3vw;
  margin-top: -1.4vw;
  cursor: pointer;
}


</style>
